var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"kind":_vm.kind,"show-avatar":_vm.showAvatar,"message":_vm.message,"user-id":_vm.userId,"with-menu":_vm.withMenu},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var showRight = ref.showRight;
var timestamp = ref.timestamp;
var timestampColor = ref.timestampColor;
var timestampIcon = ref.timestampIcon;
var message = ref.message;
var paddingClass = ref.paddingClass;
var messageClass = ref.messageClass;
var cardStyle = ref.cardStyle;
return [_c('v-card',{class:[messageClass],attrs:{"max-width":"500"}},[_c('v-card-text',{staticClass:"pa-0 word-wrap text-xs-center"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(message.data.sender.name))]),_vm._v(" added "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(message.data.recipient.name))]),_vm._v(" to this chat ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }