




















































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '@/components/messages/BaseMessage.vue';

@Component({
  name: 'business-card-message',
  components: {BaseMessage}
})
export default class BusinessCardMessage extends Vue {
  @Prop({default: true}) withTimeIcon?: boolean;
  @Prop() right?: boolean;
  @Prop() showAvatar?: boolean;
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop() kind?: boolean[];
  @Prop() moreOption?: boolean;
  @Prop({default: true}) withMenu!: boolean;
  @Prop({default: false}) channel?: boolean;

  get contact() {
    return this.message.data.contact || this.message.data.associateContact
  }

  get contactPhotoUrl() {
    return this.contact?.photoUrl?.thumbnail
  }

  get contactName() {
    return this.contact?.name || this.contact?.fullName
  }

  get contactPosition() {
    return this.contact?.position || this.contact?.description || 'Customer'
  }

  onOptionItemClicked(action, message) {
    this.$emit('option-item-clicked', action, message);
  }
}
