var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"show-avatar":_vm.showAvatar,"message":_vm.message,"user-id":_vm.userId,"with-menu":_vm.withMenu},on:{"option-item-clicked":_vm.onOptionItemClicked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var message = ref.message;
var showRight = ref.showRight;
var messageText = ref.messageText;
var timestamp = ref.timestamp;
var timestampColor = ref.timestampColor;
var timestampIcon = ref.timestampIcon;
var paddingClass = ref.paddingClass;
var cardStyle = ref.cardStyle;
return [(message.data.type === _vm.messageTypes.VIDEO_CALL_START || message.data.type === _vm.messageTypes.VIDEO_CALL_END)?_c('v-sheet',{staticClass:"px-5 system-message-video"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))])]):(message.data.type === _vm.messageTypes.FORWARDED_CASE)?_c('v-card',{staticClass:"chat-system-message",attrs:{"max-width":"500"}},[_c('v-card-text',{staticClass:"pa-0 word-wrap text-xs-center"},[_vm._v(" Conversation has been forwarded to "),_c('span',{staticClass:"primary--text",on:{"click":function($event){return _vm.$emit('contact-info', message.data.recipient.uid)}}},[_vm._v(" "+_vm._s(message.data.recipient.name)+" ")])])],1):_c('v-card',{staticClass:"chat-system-message",attrs:{"max-width":"500"}},[_c('v-card-text',{staticClass:"pa-0 word-wrap text-xs-center"},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }